import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { func, bool } from 'prop-types';

import {
  resetPassword,
  resetPasswordResetReducer,
} from '../../actions/shopActions';

import {
  CenteredSizedContainer,
  RowCentredContent,
  ColumnCenteredContent,
} from '../common/ui/container';
import { LightPurpleTitle } from '../common/ui/typo';
import { Input } from '../common/ui/input';
import { DarkPurpleButton } from '../common/ui/button';
import { Logo } from '../common/ui/image';

import { InputWrapper, TitleWrapper } from './styles';

import logoImage from '../../assets/img/ui/logo.svg';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: null,
      password: '',
      confirmPassword: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.resetPasswordStatus && this.props.resetPasswordStatus) {
      navigate('/reset_password_success');
    }
  }

  componentDidMount() {
    // Get the token in the url
    const token = window.location.hash ? window.location.hash.slice(1) : '';

    if (token) {
      this.setState({ token });
    }
  }

  componentWillUnmount() {
    this.props.resetPasswordResetReducer();
  }

  onInputChange(field) {
    return e => {
      e.preventDefault();
      this.setState({
        [field]: e.target.value,
      });
    };
  }

  onSubmit(e) {
    const { token, password, confirmPassword } = this.state;

    e.preventDefault();
    // Todo: check inputs ...
    this.props.resetPassword(token, password, confirmPassword);
  }

  redirectToHomepage = () => {
    navigate('/');
  };

  render() {
    const { wordings } = this.props;

    return (
      <CenteredSizedContainer>
        <RowCentredContent>
          <Logo src={logoImage} onClick={this.redirectToHomepage} />
        </RowCentredContent>

        <TitleWrapper>
          <LightPurpleTitle>{wordings.title}</LightPurpleTitle>
        </TitleWrapper>

        <form>
          <InputWrapper>
            <Input
              placeholder={wordings.password_placeholder}
              type="password"
              onChange={this.onInputChange('password')}
              index={1}
            />
            <Input
              placeholder={wordings.password_confirm_placeholder}
              type="password"
              onChange={this.onInputChange('confirmPassword')}
            />
          </InputWrapper>

          <ColumnCenteredContent>
            <DarkPurpleButton type="submit" onClick={this.onSubmit}>
              {wordings.button_confirm}
            </DarkPurpleButton>
          </ColumnCenteredContent>
        </form>
      </CenteredSizedContainer>
    );
  }
}

ResetPassword.proptypes = {
  resetPassword: func.isRequired,
  resetPasswordStatus: bool.isRequired,
  resetPasswordResetReducer: func.isRequired,
};

const mapStateToProps = state => {
  return {
    resetPasswordStatus: state.app.resetPassword,
  };
};

export default connect(
  mapStateToProps,
  { resetPassword, resetPasswordResetReducer }
)(ResetPassword);
