import styled from 'styled-components';

import { DARK_PURPLE } from '../../../constants/colors';

const Button = styled.button`
  border: none;
  padding: 0.5em 3em 0.5em 3em;
  font-size: 1.2em;
  border-radius: 4em;
  display: inline-block;
  font-family: 'Circular Std Bold';
  line-height: 2em;
  cursor: pointer;
  transition: 0.4s transform cubic-bezier(0.6, 0.01, 0.12, 1);
  &:hover {
    transform: scale(1.08) skew(-10deg, -8deg);
  }
`;

export const DarkPurpleButton = styled(Button)`
  background-color: ${DARK_PURPLE};
  color: white;
`;
