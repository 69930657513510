import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/layout/layout';
import ResetPasswordComponent from '../components/resetPassword';

const ResetPasswordPage = ({ data }) => (
  <Layout
    title={data.wordings.fr.reset_password.page_title}
    description={data.wordings.fr.reset_password.description}
  >
    <ResetPasswordComponent wordings={data.wordings.fr.reset_password} />
  </Layout>
);

ResetPasswordPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default ResetPasswordPage;

export const query = graphql`
  query ResetPasswordPageQuery {
    wordings {
      fr {
        reset_password {
          page_title
          description
          title
          password_rule
          password_placeholder
          password_confirm_placeholder
          error_pwd_diff
          button_confirm
        }
      }
    }
  }
`;
