import styled from 'styled-components';

import { PINK, INPUT_BORDER_COLOR } from '../../../constants/colors';

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border: 1px solid ${INPUT_BORDER_COLOR};
  font-size: 1.2em;
  color: #3c1a68;
  font-family: 'Input Regular';
  padding: 0.5em 2em 0.5em 2.5em;
  line-height: 2em;
  outline: none;
  ${p => (p.index === 1 ? 'border-bottom: none;' : '')}
`;
